<template>
    <!-- 高级盘口 -->
    <div class="container">
        <template v-if="isMobile==0">
            <div class="header">
                <div class="title" @click="handleFull"><i class="iconfont2 icon-quanping"></i>{{$t('stocks.advanced')}}</div>
                <div class="title disabled" @click="handleFull"><i class="iconfont icon-fullscreen"></i>{{$t('stocks.common')}}</div>
            </div>
            <div class="stocks_full">
                <div class="left">
                    <div class="hd">
                        <div class="action">{{$t('common.operation')}}</div>
                        <div class="need">
                            {{$t('stocks.coins')}}
                        </div>
                        <!-- <div class="need" v-show="!isCoins">
                            {{$t('stocks.coinsMoney')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('left')"></i>
                        </div> -->
                        <div class="retail switch" v-show="isCoins">
                            {{$t('stocks.investors')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('left')"></i>
                        </div>
                        <div class="retail switch" v-show="!isCoins">
                            {{$t('stocks.investorsTotal')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('left')"></i>
                        </div>
                        <div class="mine">{{$t('stocks.myself')}}</div>
                        <div class="number switch" v-show="isCoins">
                            {{$t('stocks.totalQuantity')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('left')"></i>
                        </div>
                        <div class="number switch" v-show="!isCoins">
                            {{$t('stocks.totalCumulative')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('left')"></i>
                        </div>
                        <div class="price">{{$t('common.pirce')}}</div>
                    </div>
                    <div class="list">
                        <div class="item" v-for="(item,index) in bidsList2" :key="index">
                            <div class="action">
                                <div v-if="!!item.id&&item.id!='0'" class="btn" @click="handleCancel(item,'buy')"><span>{{$t('common.cancelOrder')}}</span></div>
                            </div>
                            <div class="need">{{item.count}}</div>
                            <div class="retail" v-show="isCoins">{{item.users}}</div>
                            <div class="retail" v-show="!isCoins">{{item.count}}</div>
                            <div class="mine">{{item.mine}}</div>
                            <div class="number" v-show="isCoins">{{item.amount}}</div>
                            <div class="retail" v-show="!isCoins">{{item.count_all}}</div>
                            <div class="price green">{{item.price}}</div>
                        </div>
                    </div>
                </div>
                
                <div class="right">
                    <div class="hd">
                        <div class="price">{{$t('common.pirce')}}</div>
                        <div class="number switch" v-show="isCoins2">
                            {{$t('stocks.totalQuantity')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('right')"></i>
                        </div>
                        <div class="number switch" v-show="!isCoins2">
                            {{$t('stocks.totalCumulative')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('right')"></i>
                        </div>
                        <div class="mine">{{$t('stocks.myself')}}</div>
                        <div class="retail switch" v-show="isCoins2">
                            {{$t('stocks.investors')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('right')"></i>
                        </div>
                        <div class="need switch" v-show="!isCoins2">
                            {{$t('stocks.investorsTotal')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('right')"></i>
                        </div>
                        <div class="need">
                            {{$t('stocks.coinsMoney')}}
                        </div>
                        <div class="action">{{$t('common.operation')}}</div>
                    </div>
                    <div class="list">
                        <div class="item" v-for="(item,index) in asksList2" :key="index">
                            <div class="price red">{{item.price}}</div>
                            <div class="number" v-show="isCoins2">{{item.amount}}</div>
                            <div class="retail" v-show="!isCoins2">{{item.count_all}}</div>
                            <div class="mine">{{item.mine}}</div>
                            <div class="retail" v-show="isCoins2">{{item.users}}</div>
                            <div class="retail" v-show="!isCoins2">{{item.count}}</div>
                            <div class="need">{{item.count_money}}</div>
                            <div class="action">
                                <div v-if="!!item.id&&item.id!='0'" class="btn" @click="handleCancel(item,'sell')"><span>{{$t('common.cancelOrder')}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="isMobile==1">
            <div class="stocks_full_phone">
                <div class="left">
                    <div class="hd">
                        <div class="action">{{$t('common.operation')}}</div>
                        <div class="need">
                            {{$t('stocks.coins')}}
                        </div>
                        <!-- <div class="need" v-show="!isCoins">
                            {{$t('stocks.coinsMoney')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('left')"></i>
                        </div> -->
                        <div class="retail switch" v-show="isCoins">
                            {{$t('stocks.investors')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('left')"></i>
                        </div>
                        <div class="retail switch" v-show="!isCoins">
                            {{$t('stocks.investorsTotal')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('left')"></i>
                        </div>
                        <div class="mine">{{$t('stocks.myself')}}</div>
                        <div class="number switch" v-show="isCoins">
                            {{$t('stocks.totalQuantity')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('left')"></i>
                        </div>
                        <div class="number switch" v-show="!isCoins">
                            {{$t('stocks.totalCumulative')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('left')"></i>
                        </div>
                        <div class="price">{{$t('common.pirce')}}</div>
                    </div>
                    <div class="list">
                        <div class="item" v-for="(item,index) in bidsList2" :key="index">
                            <div class="action">
                                <div v-if="!!item.id&&item.id!='0'" class="btn" @click="handleCancel(item,'buy')"><span>{{$t('common.cancelOrder')}}</span></div>
                            </div>
                            <div class="need">{{item.count}}</div>
                            <div class="retail" v-show="isCoins">{{item.users}}</div>
                            <div class="retail" v-show="!isCoins">{{item.count}}</div>
                            <div class="mine">{{item.mine}}</div>
                            <div class="number" v-show="isCoins">{{item.amount}}</div>
                            <div class="retail" v-show="!isCoins">{{item.count_all}}</div>
                            <div class="price green">{{item.price}}</div>
                        </div>
                    </div>
                </div>
                
                <div class="right">
                    <div class="hd">
                        <div class="price">{{$t('common.pirce')}}</div>
                        <div class="number switch" v-show="isCoins2">
                            {{$t('stocks.totalQuantity')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('right')"></i>
                        </div>
                        <div class="number switch" v-show="!isCoins2">
                            {{$t('stocks.totalCumulative')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('right')"></i>
                        </div>
                        <div class="mine">{{$t('stocks.myself')}}</div>
                        <div class="retail switch" v-show="isCoins2">
                            {{$t('stocks.investors')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('right')"></i>
                        </div>
                        <div class="need switch" v-show="!isCoins2">
                            {{$t('stocks.investorsTotal')}}
                            <i class="iconfont2 icon-qiehuan" @click="switchData('right')"></i>
                        </div>
                        <div class="need">
                            {{$t('stocks.coinsMoney')}}
                        </div>
                        <div class="action">{{$t('common.operation')}}</div>
                    </div>
                    <div class="list">
                        <div class="item" v-for="(item,index) in asksList2" :key="index">
                            <div class="price red">{{item.price}}</div>
                            <div class="number" v-show="isCoins2">{{item.amount}}</div>
                            <div class="retail" v-show="!isCoins2">{{item.count_all}}</div>
                            <div class="mine">{{item.mine}}</div>
                            <div class="retail" v-show="isCoins2">{{item.users}}</div>
                            <div class="retail" v-show="!isCoins2">{{item.count}}</div>
                            <div class="need">{{item.count_money}}</div>
                            <div class="action">
                                <div v-if="!!item.id&&item.id!='0'" class="btn" @click="handleCancel(item,'sell')"><span>{{$t('common.cancelOrder')}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'stocksFull.styl';
</style>

<script>
import {mapGetters} from 'vuex'

// api
import {
    getPankouHigh,
    tradeCancel,
} from '@/api/markets'
var timeHigh;
export default {
    data(){
        return{
            exchange: localStorage.getItem('exchange') || (localStorage.getItem('lang')=='zh'?'币安':'bian'),
            web: localStorage.getItem('web') || 'bian',
            yenUnit1: localStorage.getItem('yenUnit1') || 'btc',
            yenUnit2: localStorage.getItem('yenUnit2') || 'usdt',
            web_id: localStorage.getItem('web_id')||'5',
            web_type:localStorage.getItem('web_type')||'0',//交易所类型 0-cex、1-dex
            
            // 切换
            isCoins:true,
            isCoins2:true,
            // 数据
            bidsList2:[{price:0,amount:0,mine:0,users:0,count:0,count_money:0}],
            asksList2:[{price:0,amount:0,mine:0,users:0,count:0,count_money:0}],

            // query:'',
        }
    },
    computed:{
		...mapGetters(['account','device','isMobile']),
    },
    created(){
        // this.query=this.$route.query
        // 获取高级盘口
        this.getPankouHigh()
    },
    methods:{
        // 撤单
        handleCancel(item,type) {
            this.$confirm(this.$t('tip.cancelOrder'), this.$t('tip.tips'), {
                confirmButtonText: this.$t('common.confirm'),
                cancelButtonText: this.$t('common.back'),
                center: true,
                showClose: false,
                customClass: 'deleteOk',
            }).then(() => {
                tradeCancel({
                    web:this.web,
                    account:this.account,
                    currency:this.yenUnit1+'_'+this.yenUnit2,
                    order_id:item.id,
                    type:type?type:item.side,
                }).then(res=>{
                    this.$message({
                        message: this.$t('tip.cancelSuc'),
                        type: 'success',
                        center: true,
                        offset: 100,
                        customClass: 'shotMsg',
                    });
                    this.getPankouHigh()
                })
            }).catch(() => {
                // 取消
            });
        },
        getPankouHigh(){
			var currency=this.yenUnit1+'_'+this.yenUnit2;
            // 高级盘口
            getPankouHigh({
                web:this.web,
                account:this.account,
                currency,
            }).then(res=>{
                this.bidsList2=res.bids;
                this.asksList2=res.asks;
            })
            
            if(timeHigh){
                clearInterval(timeHigh)
                timeHigh=''
            }
            timeHigh=setInterval(()=>{
                // 高级盘口
                getPankouHigh({
                    web:this.web,
                    account:this.account,
                    currency,
                }).then(res=>{
                    this.bidsList2=res.bids;
                    this.asksList2=res.asks;
                })
            },15000)
        },
        // 高级盘口 切换
        switchData(box){
            // console.log('switchData',box)
            if(box=='left'){
                this.isCoins=!this.isCoins
            }else{
                this.isCoins2=!this.isCoins2
            }
        },
        // 回到普通盘口
        handleFull(){
            this.$router.push({
                path:'/stocks',
                // query:this.query
            })
        },
    },
    beforeDestroy() {
        if(timeHigh){
            clearInterval(timeHigh)
            timeHigh=''
        }
    },
}
</script>